import React from 'react';
import { makeStyles, darken } from '@material-ui/core/styles';
import {
  Grid,
} from '@material-ui/core';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import TwitterIcon from '../../../images/icons/TwitterIcon';
import FacebookIcon from '../../../images/icons/FacebookIcon';
import LinkedinIcon from '../../../images/icons/LinkedinIcon';
import WancloudsLogo from '../../../images/icons/wanclouds-logo';
import YoutubeIcon from '../../../images/icons/YoutubeIcon';

const useStyles = makeStyles(({ palette }) => ({
  section: {
    background: '#010E28',
    color: palette.primary.contrastText,
  },
  iconWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
    marginRight: 12,
    cursor: 'pointer',
    '&:hover': {
      color: '#4977e5',
    },
  },
  link: {
    borderRadius: 4,
    '&:hover': {
      background: darken('#003265', 0.2),
    },
  },
  privacyLink: {
    '&:hover': {
      color: darken('#4977e5', 0.2),
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <section className={`section ${classes.section} pb-5`}>
      <Container>
        <Grid container spacing={3}>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <div className="footer1__about">
              <WancloudsLogo color="#fff" width="150" />
              <p className="my-6 text-inherit text-14">
                Headquartered in Santa Clara, CA. Wanclouds offer products and services to automate and solve challenges
                related to Cloud Migrations & Disaster Recovery.
              </p>
              <p className="my-6 text-inherit text-14">
                2445 Augustine Drive, Suite 401, Santa Clara, CA 95054,
                USA
              </p>
              <div className="flex flex-wrap">
                <a href="https://www.facebook.com/wanclouds" target="_blank" rel="noreferrer">
                  <div className={classes.iconWrapper}>
                    <FacebookIcon className="text-16" fontSize="small" />
                  </div>
                </a>
                <a href="https://twitter.com/WancloudsInc" target="_blank" rel="noreferrer">
                  <div className={classes.iconWrapper}>
                    <TwitterIcon className="text-16" fontSize="small" />
                  </div>
                </a>
                <a href="https://www.youtube.com/channel/UC0JORe8AJnoWZWfi1Ftecag" target="_blank" rel="noreferrer">
                  <div className={classes.iconWrapper}>
                    <YoutubeIcon className="text-16" fontSize="small" />
                  </div>
                </a>
                <a href="https://www.linkedin.com/company/wanclouds-inc/" target="_blank" rel="noreferrer">
                  <div className={classes.iconWrapper}>
                    <LinkedinIcon className="text-16" fontSize="small" />
                  </div>
                </a>
              </div>
            </div>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={6}>
            <div className="footer1__about">
              <h4 className="text-18 font-normal m-0">Product</h4>
              <div className="mt-4 mb-6 ml--4">
                <a href="https://wanclouds.net/vpc+">
                  <div
                    className={clsx(
                      'flex items-center py-2 cursor-pointer px-4 w-full',
                      classes.link,
                    )}
                  >
                    <span className="text-13">VPC +</span>
                  </div>
                </a>
              </div>
            </div>
            <div className="footer1__about">
              <h4 className="text-18 font-normal m-0">Offerings</h4>
              <div className="mt-4 mb-6 ml--4">
                <a href="https://wanclouds.net/draas">
                  <div
                    key="migrationAsAservices"
                    className={clsx('flex items-center py-2 cursor-pointer px-4 w-full', classes.link)}
                  >
                    <span className="text-13">Disaster Recovery as a Service</span>
                  </div>
                </a>
                <a href="https://wanclouds.net/maas">
                  <div
                    key="migrationAsAservices"
                    className={clsx('flex items-center py-2 cursor-pointer px-4 w-full', classes.link)}
                  >
                    <span className="text-13">Migration as a Services</span>
                  </div>
                </a>
                <a href="https://wanclouds.net/services">
                  <div
                    key="onGoingServices"
                    className={clsx('flex items-center py-2 cursor-pointer px-4 w-full', classes.link)}
                  >
                    <span className="text-13">Ongoing Services</span>
                  </div>
                </a>
              </div>
            </div>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={6}>
            <div className="footer1__about">
              <h4 className="text-18 font-normal m-0">Resources</h4>
              <div className="mt-4 mb-6 ml--4">
                <a href="https://www.wanclouds.net/blog">
                  <div
                    key="blog"
                    className={clsx('flex items-center py-2 cursor-pointer px-4 w-full', classes.link)}
                  >
                    <span className="text-13">Blog</span>
                  </div>
                </a>
                <a href="https://wanclouds.net/configuration-migration-and-management">
                  <div
                    key="configurations"
                    className={clsx('flex items-center py-2 cursor-pointer px-4 w-full', classes.link)}
                  >
                    <span
                      className="text-13"
                    >
                      Configurations
                    </span>
                  </div>
                </a>
                <a href="https://docs.wanclouds.net">
                  <div
                    className={clsx(
                      'flex items-center py-2 cursor-pointer px-4 w-full',
                      classes.link,
                    )}
                  >
                    <span className="text-13">Knowledge Base</span>
                  </div>
                </a>
                <a href="https://support.wanclouds.net">
                  <div
                    className={clsx(
                      'flex items-center py-2 cursor-pointer px-4 w-full',
                      classes.link,
                    )}
                  >
                    <span className="text-13">Support</span>
                  </div>
                </a>
              </div>
            </div>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <div className="footer1__about">
              <h4 className="text-18 font-normal m-0">About</h4>
              <div className="mt-4 mb-6 ml--4">
                <a href="https://wanclouds.net/about-us">
                  <div
                    className={clsx(
                      'flex items-center py-2 cursor-pointer px-4 w-full',
                      classes.link,
                    )}
                  >
                    <span className="text-13">Company</span>
                  </div>
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="text-center">
          <h4 className="text-13 font-normal py-3">
            © 2024 all rights reserved, Wanclouds Inc.
            <a href="https://wanclouds.net/privacy"><span className={classes.privacyLink}>Privacy Policy</span></a>
          </h4>
        </div>
      </Container>
    </section>
  );
};

export default Footer;
